<template>
  <!-- 平台课程详情 -->
  <div class="pageContol formCom coursePage">
    <div class="framePage">
      <div class="framePage-title">
        <span>
          <em>当前位置：</em>
          <a href="javascript:;">平台资源</a>
          <i>></i>
          <a href="javascript:;" @click="$router.back()">平台课程/课件</a>
          <i>></i>
          <a href="javascript:;" @click="init" class="cur-a">课程预览</a>
        </span>
      </div>
      <div class="framePage-body">
        <div class="framePage-scroll">
          <div class="ovy-a">
            <h3 style="padding-bottom:20px;">课程基本信息</h3>
            <el-form ref="ruleForm" :model="ruleForm" :rules="rules" label-width="10rem" :inline="true">
              <el-form-item label="课程名称：" class="form-inline">
                <p>{{ ruleForm.courseName }}</p>
              </el-form-item>
              <el-form-item v-show="ruleForm.trainTypeNamePath" label="培训类型：" class="form-inline">
                <p>{{ ruleForm.trainTypeNamePath || '' }}</p>
              </el-form-item>
              <el-form-item v-show="ruleForm.industryNamePath" label="行业类型：" class="form-inline">
                <p>{{ ruleForm.industryNamePath || '' }}</p>
              </el-form-item>
              <el-form-item v-show="ruleForm.postName" label="岗位类型：" class="form-inline">
                <p>{{ ruleForm.postName || '' }}</p>
              </el-form-item>
              <el-form-item v-show="ruleForm.occupationNamePath" label="职业/工种：" class="form-inline">
                <p>{{ ruleForm.occupationNamePath || '' }}</p>
              </el-form-item>
              <el-form-item v-show="ruleForm.trainLevelName" label="培训等级：" class="form-inline">
                <p>{{ ruleForm.trainLevelName || '' }}</p>
              </el-form-item>
            </el-form>
            <el-form ref="ruleForm" :model="ruleForm" :rules="rules" label-width="10rem">
              <el-form-item label="课程详情：" prop="context" class="form-item">
                <div v-html="ruleForm.context" ref="editor" style="width:100%;position: relative;z-index: 2;" />
              </el-form-item>
              <el-form-item label="课程图片：" prop="thumbnail">
                <div class="img-el-upload" style="width:256px;padding-top:10px;">
                  <el-image :src="ruleForm.thumbnail || require('@/assets/develop.png')" fit="contain"></el-image>
                </div>
              </el-form-item>
              <el-form-item label="讲师备注：" prop="lecturer">
                <p>{{ ruleForm.lecturer || '无' }}</p>
              </el-form-item>
              <el-form-item label="备注：" prop="remark">
                <p>{{ ruleForm.remark || '--' }}</p>
              </el-form-item>
            </el-form>
            <h3 style="padding-bottom:20px;">课程章节</h3>
            <div style="display: flex">
              <div style="flex:1">
                <section class="chapterl-trees">
                  <el-tree :data="data" node-key="id" default-expand-all v-if="data">
                    <div class="custom-tree-node df" style="align-items:center" slot-scope="{ node, data }">
                      <p v-if="data.level === 1">
                        <!-- <span style="margin-right:10px;">一级</span> -->
                        <span>{{ node.label }}</span>
                      </p>
                      <template v-else-if="data.level === 2">
                        <!-- <span v-if="styleType == '1'" style="margin-right:10px;">二级</span> -->
                        <!-- <span v-else style="margin-right:10px;">一级</span> -->
                        <span v-if="data.list.length > 0" style="display: flex;flex-direction: row;align-items: center;">
                          <i class="courseIcon" v-if="data.list[0].kpointId"></i>
                          <i class="paperIcon" v-else-if="data.list[0].paperId"></i>
                        </span>
                        <span v-else style="display: flex;flex-direction: row;align-items: center;">
                          <i class="nullIcon"></i>
                        </span>
                        <span class="ellipsis">{{ data.label }}</span>
                        <span class="trees-btns" style="margin-left:57px" :canDarg="data.list.length">
                          <el-button class="bgc-bv showBtn" size="mini" @click.stop="() => showVideo(data)">预览</el-button>
                          <template v-if="isProvider(data.providerId)">
                            <template v-if="!data.haveCollect">
                              <el-popover
                                placement="right"
                                width="300"
                                :ref="`popover-${data.id}`"
                                @hide="clearPop">
                                <div>选择标签</div>
                                <el-input size="small" placeholder="请输入内容" v-model="tagName">
                                  <template slot="append"><el-button class="bgc-bv" size="small" @click="addTag">新增</el-button></template>
                                </el-input>
                                <div style="margin: 10px 0;">
                                  <el-tag
                                    v-for="(item,index) in tagList"
                                    :key="item.labelId"
                                    style="cursor: pointer;margin: 5px;"
                                    @click="currentLabelId == item.labelId?(currentLabelId = ''):(currentLabelId = item.labelId)"
                                    :effect="(currentLabelId === item.labelId)?'dark':'plain'">
                                    {{ item.labelName }}
                                  </el-tag>
                                </div>
                                <div style="text-align: center;margin: 10px 0;">
                                  <el-button class="bgc-bv" size="mini" @click="doCollect(data)">确定</el-button>
                                </div>
                                <span slot="reference" @click="closePopover" class="staricon" >
                                  <el-button size="mini" icon="el-icon-star-off">收藏</el-button>
                                </span>
                              </el-popover>
                            </template>
                            <span v-else class="staricon" @click="cancelCollect(data)">
                              <el-button style="color: #E6A23C" size="mini" icon="el-icon-star-on">已收藏</el-button>
                            </span>
                          </template>
                        </span>
                      </template>
                      <template v-else>
                        <p class="df" style="align-items:center">
                          <i class="el-icon-tickets" style="margin-right:5px"></i>
                          <span class="ellipsis" style="margin-right:23px">{{ node.label }}</span>
                          <span class="trees-btns">
                            <el-button class="bgc-bv showBtn" size="mini"
                              @click.stop="() => showPaper(data)">预览</el-button>
                            <template v-if="isProvider(data.providerId)">
                              <template v-if="!data.haveCollect">
                                <el-popover
                                placement="right"
                                width="300"
                                :ref="`popover-${data.id}`"
                                @hide="clearPop">
                                <div>选择标签</div>
                                <el-input size="small" placeholder="请输入内容" v-model="tagName">
                                  <template slot="append"><el-button class="bgc-bv" size="small" @click="addTag">新增</el-button></template>
                                </el-input>
                                <div style="margin: 10px 0;">
                                  <el-tag
                                    v-for="(item,index) in tagList"
                                    :key="item.labelId"
                                    style="cursor: pointer;margin: 5px;"
                                    @click="currentLabelId == item.labelId?(currentLabelId = ''):(currentLabelId = item.labelId)"
                                    :effect="(currentLabelId === item.labelId)?'dark':'plain'">
                                    {{ item.labelName }}
                                  </el-tag>
                                </div>
                                <div style="text-align: center;margin: 10px 0;">
                                  <el-button class="bgc-bv" size="mini" @click="doCollect(data)">确定</el-button>
                                </div>
                                <span slot="reference" @click="closePopover" class="staricon" >
                                  <el-button size="mini" icon="el-icon-star-off">收藏</el-button>
                                </span>
                              </el-popover>
                            </template>
                            <span v-else class="staricon" @click="cancelCollect(data)">
                              <el-button style="color: #E6A23C" size="mini" icon="el-icon-star-on">已收藏</el-button>
                            </span>
                            </template>
                          </span>
                        </p>
                      </template>
                    </div>
                  </el-tree>
                </section>
              </div>
            </div>
          </div>
        </div>
        <div class="butbox">
          <el-button class="bgc-bv" @click="$router.back()">返回</el-button>
          <el-button class="bgc-bv" @click="salesState"
            :disabled="(addState == 0 && notState == 0) ? false : true">加入我的课程</el-button>
        </div>
      </div>
    </div>
    <el-dialog v-if="dialogVisible1" :title="videotitle" :visible.sync="dialogVisible1" width="60%"
      :before-close="handleClose">
      <player_2 :videoId="videoId" :courseId="courseId" :kpointId="kpointId" :videoTime="videoTime"
        :kpointSource="kpointSource" v-if="videoType" ref="player" />
      <player_1 :watermark="true" :videoId="videoId" :courseId="courseId" :kpointId="kpointId" :videoTime="videoTime"
        :kpointSource="kpointSource" v-else ref="player" />
    </el-dialog>
    <PaperShow ref="PaperShow" />
  </div>
</template>
<script>
import PaperShow from "@/views/resourse/popup/PaperShow";
import player_1 from "@/components/player";
import player_2 from "@/components/playerTrysee";
import { createSwigger, distorySwigger } from "../../utils/swigger";
export default {
  name: "coursePages",
  components: {
    PaperShow,
    player_1,
    player_2
  },
  data() {
    return {
      addState: this.$route.query.addState || "",
      notState: this.$route.query.notState || "",
      detailResource: {},
      courseId: this.$route.query.courseId || "",
      styleType: "1",
      props: {
        value: "id",
        label: "label",
        emitPath: false
      },
      ruleForm: {
        agreement: false,
        resource: "",
        thumbnailId: "",
        thumbnail: "",
        context: "", // 富文本
        salesState: "20",
        trainTypeName: "",
        lecturer: '',
        remark: '',
      },
      // tree
      draggable: true,
      treeLable: "",
      data: [],
      defaultProps: {
        children: "children",
        label: "label"
      },
      styleStu: false,
      videoType: null, // true-可以看；false-试看
      dialogVisible1: false,
      videotitle: "",
      videoTime: "",
      status: 1,
      pathUrl: "", // 判断是从哪个页面来的
      hasProviderId:["10981","701140","635036"],//只能收藏北京晟融、江西智越、南京慧聚机构的课件
      dialogCollect:false,
      tagName:'',
      currentLabelId:'',
      tagList:[],
    };
  },
  mounted() {
    this.init();
  },
  methods: {
    init() {
      this.getDetail();
      this.getTagList();
    },
    clearPop(){
      this.tagName = ''
      this.currentLabelId = ''
    },
    closePopover() { 
      for (const key in this.$refs) {
        if (key.indexOf('popover-') !== -1) {
          this.$refs[key].doClose();
        }
      }
    },
    // 获取标签列表
    getTagList(flag){
      this.$post("/collect/label/queryListLimitNum", { num:5 }).then(
        res => {
          this.tagList = res.data
          flag&&(this.currentLabelId = res.data[0].labelId)
        }
      );
    },
    // 新增标签
    addTag(){
      if(!this.tagName){
        this.$message.error('请填写标签名称！')
        return
      }
      this.$post("/collect/label/insert", { labelName:this.tagName }).then(
        res => {
          this.tagName = ''
          this.getTagList(true)
        }
      );
    },
    // 收藏
    doCollect(data){
      console.log(data);
      this.$post("/collect/collect/doCollect", { catalogName:data.label,courseKpointId:data.id,labelId:this.currentLabelId }).then(
        res => {
          data.haveCollect = true
          data.collectId = res.data
          this.clearPop()
        }
      );
    },
    // 取消收藏
    cancelCollect(data){
      this.$post("/collect/collect/cancel", { collectId:data.collectId }).then(
        res => {
          data.haveCollect = false
          data.collectId = ''
        }
      );
    },
    // 是否属于北京晟融、江西智越、南京慧聚机构的课件
    isProvider(id){
      return this.hasProviderId.includes(id)
    },
    getDetail() {
      // 联安众的业务
      if (this.pathUrl == '/web/resourse/courseListLiananzhong') {
        this.$post("/biz/qa/QaCourse/pullQaCourseInfo", { CURRICULUM_ID: this.courseId }).then(
          result => {
            console.log(result)
            if (result.status === "0") {
              this.creatData(result.data);
            }
          }
        );
      } else {
        this.$post("/biz/course/displayPlatform", { courseId: this.courseId }).then(
          result => {
            console.log(result)
            if (result.status === "0") {
              this.creatData(result.data);
            }
          }
        );
      }
    },
    creatData(data = {}) {
      let ret = {
        agreement: false,
        courseName: data.courseName,
        context: data.context,
        thumbnailId: data.logo,
        salesState: data.salesState,
        thumbnail: data.logoURL,
        lecturer: data.lecturer,
        remark: data.remark,
        resource: "",
        trainTypeNamePath: data.trainTypeNamePath,
        postName: data.postName,
        industryNamePath: data.industryNamePath,
        occupationNamePath: data.occupationNamePath,
        trainLevelName: data.trainLevelName
      };
      this.styleType = data.chapterSchema ? "1" : "2";
      this.ruleForm = ret;
      let list = [];
      if (data.chapterSchema) {
        data.nodes.forEach(element => {
          let nodeList = [];
          list.push({
            label: element.catalogName,
            id: element.courseKpointId,
            children: nodeList,
            level: 1
          });
          (element.child || []).forEach(obj => {
            nodeList.push(this.section2Item(obj));
          });
        });
      } else {
        data.nodes.forEach(element => {
          list.push(this.section2Item(element));
        });
      }
      this.data = list;
    },
    section2Item(obj) {
      let children = [];
      if (obj.paperId) {
        children = [
          {
            paperId: obj.paperId,
            label: obj.paperName,
            level: 3
          }
        ];
      }
      let listArr = [];
      if (obj.kpointVideoId) {
        obj.list = listArr;
        listArr.push({
          kpointVideoId: obj.kpointVideoId,
          kpointName: obj.kpointName,
          kpointDuration: obj.kpointDuration,
          kpointId: obj.kpointId,
          kpointLessonNum: obj.kpointLessonNum,
          kpointLogo: obj.kpointLogo,
          kpointType: obj.kpointType,
          qualificationId: obj.qualificationId,
          kpointDeleted: obj.kpointDeleted, // 课件是否删除了
          progress: 100,
          kpointSource: obj.kpointSource
        });
      } else if (obj.paperId) {
        obj.list = listArr;
        listArr.push({
          progress: 100,
          buyId: obj.buyId || "",
          paperId: obj.paperId,
          paperName: obj.paperName
        });
      }
      //判断kpointId存在，paperId存在，带试卷的课件，kpointId不存在，paperId存在是单元测试
      if (obj.kpointId) {
        return {
          label: obj.catalogName,
          id: obj.courseKpointId,
          kpointId: obj.kpointId,
          level: 2,
          list: listArr,
          haveCollect:obj.haveCollect,
          collectId:obj.collectId,
          providerId:obj.providerId,
          children: children
        };
      } else {
        console.log(obj);
        return {
          label: obj.catalogName,
          id: obj.courseKpointId,
          kpointId: obj.kpointId,
          level: 2,
          list: listArr,
          children: [],
          haveCollect:obj.haveCollect,
          collectId:obj.collectId,
          providerId:obj.providerId,
          paperId: obj.paperId,
          paperName: obj.paperName,
        };
      }
    },
    // 加入
    salesState() {
      // 调 - 根据类型，机构id或区划id查询提示信息（加课和配课的时候用）
      this.$post("/course/useset/selectListByType", {
        courseId: this.courseId,
      })
        .then((res) => {
          if (res.status == 0) {
            if (res.data && res.data.tipcontent) {
              this.$confirm(res.data.tipcontent, '提示')
                .then((_) => {
                  // 加入
                  this.$post("/biz/course/insertPlatformCourseToMine", {
                    courseId: this.courseId
                  })
                    .then(ret => {
                      if (ret.status == 0) {
                        this.$message({
                          type: "success",
                          message: "已成功加入我的课程"
                        });
                        setTimeout(() => {
                          this.$router.push({
                            path: "/web/courseList",
                            query: {
                              refresh: true,
                              refName: 'first'
                            }
                          });
                        }, 300);

                      }
                    })
                    .catch(() => {
                      return;
                    });
                })
                .catch((_) => { });
            } else {
              // 加入
              this.$post("/biz/course/insertPlatformCourseToMine", {
                courseId: this.courseId
              })
                .then(ret => {
                  if (ret.status == 0) {
                    this.$message({
                      type: "success",
                      message: "已成功加入我的课程"
                    });
                    setTimeout(() => {
                      this.$router.push({
                        path: "/web/courseList",
                        params: {
                          refresh: true,
                          refName: 'first'
                        }
                      });
                    }, 300);
                  }
                })
                .catch(() => {
                  return;
                });
            }
          }
        })
        .catch(() => {
          return;
        });

    },
    // 预览
    showVideo(data) {
      let _this = this;
      //单元测试打开是题库
      if (data.list[0].kpointVideoId) {
        _this
          .$post("/biz/courseware/auth", { videoId: data.list[0].kpointVideoId })
          .then(result => {
            _this.videoId = data.list[0].kpointVideoId;
            _this.videoType = result.data.trySee;
            _this.videoTime = result.data.duration;
            _this.videotitle = data.label;
            _this.courseId = _this.courseId;
            _this.kpointId = data.kpointId;
            _this.kpointSource = data.list[0].kpointSource;
            _this.dialogVisible1 = true;
          })
      }
      if (!data.list[0].kpointVideoId && data.paperId) {
        this.showPaper(data);
      }
    },
    showPaper(data) {
      this.$refs.PaperShow.showPopUp(data.paperId); // 打开弹窗预览试题
    },
    handleClose() {
      (this.dialogVisible1 = false), (this.status = 3);
      this.$refs.player.dd()
      distorySwigger()
    },
    // // 预览
    // showPaper(data) {
    //   this.$router.push({
    //     path: "/web/testPaperEdit",
    //     query: {
    //       stu: false,
    //       paperId: data.paperId,
    //       btn:true
    //     }
    //   });
    //   // this.$store.dispatch("handleVideoUrl", data.list[0].kpointVideoId);
    // }
  },
  watch: {
    $route: {
      handler: function (n, o) {
        this.pathUrl = n.path
      },
      immediate: true
    }
  },
};
</script>
<style lang="less">
.form-inline {
  width: 45%;

  p {
    width: 15rem;
  }
}
.staricon{
  margin-left: 5px;
  font-size: 15px;
  >i{
    font-size: 16px;
  }
}
.formCom {
  .el-form-item {
    margin-bottom: 5px;
  }

  .chapterl-btns {
    .el-button {
      padding: 8px;
    }
  }

  .el-cascader {
    width: 100%;
  }

  .el-progress {
    width: 200px;
  }

  .chapterl-trees {
    padding: 15px 15px 15px 0;

    .el-tree-node__content {
      height: 36px;
    }

    .draggable-item {
      margin-left: 5px;

      em {
        margin-right: 5px;
      }
    }

    .trees-btns {
      .showbtn {
        color: #fff;
      }

      margin-left: 15px;

      &>.handle {
        // color: #4089fa;
        font-size: 14px;
        cursor: pointer;

        &:not(:last-child) {
          margin-right: 5px;
        }
      }
    }
  }

  .chapteru-lists {
    padding: 15px 15px 15px 0;

    .el-divider--horizontal {
      margin: 5px;
    }

    .cl-subhint {
      display: flex;

      span {
        font-size: 14px;
        color: #666;

        &:nth-child(2) {
          margin-left: 15px;
        }
      }

      em {
        margin: 0 5px;
      }
    }

    .cl-btns {
      i {
        cursor: pointer;

        &:not(:last-child) {
          margin-right: 5px;
        }
      }
    }
  }

  .cl-img-box {
    .el-icon-receiving {
      font-size: 40px;
      color: #999;
      margin-right: 15px;
    }
  }

  .pageForm-btns {
    padding: 15px 0;
    text-align: center;

    .el-button {
      span {
        min-width: 4em;
      }
    }
  }
}

.img-el-upload {
  padding: 20px 0;
  min-height: 120px;
}

.butbox {
  display: flex;
  justify-content: center;

  .el-button.is-disabled,
  .el-button.is-disabled:focus,
  .el-button.is-disabled:hover {
    background: #a9a9a9;
    border-color: #a9a9a9;
    color: #fff;
  }
}
</style>
<style lang="less">
.coursePage {
  .ellipsis {
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: nowrap;
    width: 20rem;
    font-size: 14px;
  }

  .courseIcon {
    width: 14px;
    height: 14px;
    margin-right: 5px;
    display: inline-block;
    background: url("../../assets/courseIcon.png") no-repeat;
    background-size: cover;
  }

  .paperIcon {
    width: 14px;
    height: 14px;
    margin-right: 5px;
    display: inline-block;
    background: url("../../assets/paperIcon.png") no-repeat;
    background-size: cover;
  }

  .nullIcon {
    width: 14px;
    height: 14px;
    margin-right: 5px;
    display: inline-block;
  }
}
</style>

